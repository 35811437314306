import { truncate } from "~/lib/string";
import Carousel from "./carousel";
import Text from "./text";
import Rating from "~/assets/icons/rating.svg";

type Props = {
  slides: {
    id: string;
    author: string;
    date: string;
    rating: number;
    text: string;
    url: string;
    profilePicture: string;
    source: string;
    ts: number;
    postedAt: string;
  }[];
};

export default function ReviewCarousel({ slides = [] }: Props) {
  return (
    <Carousel>
      {slides.map(({ text, author, profilePicture, date }) => (
        <blockquote className="bg-white m-5 p-[20px] shadow-lg shadow-primary border-2 border-primary block md:h-[280px] relative">
          <div className="absolute bottom-0 left-0 right-0 top-0 z-2 shadow-[0_0_8px_rgba(255,16,142,0.3)]" />
          <div className="relative z-3">
            <cite>
              <div className="flex space-x-2 items-center">
                <div>
                  <img
                    decoding="async"
                    loading="lazy"
                    width={40}
                    height={40}
                    src={profilePicture}
                    alt={author}
                  />
                </div>

                <div>
                  <div className="not-italic font-bold">
                    {truncate(author, 25)}
                  </div>
                  <div>
                    <span className="align-middle inline-block">
                      <img
                        decoding="async"
                        loading="lazy"
                        src={Rating.src}
                        width={Rating.width}
                        height={Rating.height}
                        alt="Review rating stars"
                      />
                    </span>
                    <span className="align-middle inline-block not-italic font-bold text-sm ml-2">
                      {date}
                    </span>
                  </div>
                </div>
              </div>
            </cite>
            <Text p classNames="mt-4 ">
              {truncate(text, 180)}
            </Text>
          </div>
        </blockquote>
      ))}
    </Carousel>
  );
}
